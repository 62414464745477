import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    mergeMap,
    shareReplay,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import {
    ActiveCustomerFragment,
    CartFragment,
    CustomerShippingLocation,
    GetActiveOrderAndCustomerQuery,
    GetDefaultShippingLocationDocument,
} from '../../../common/gql/graphql';
import { DataService } from '../data/data.service';
import { StateService } from '../state.service';
import { GET_ACTIVE_ORDER_AND_CUSTOMER } from './active.graphql';

export type WishlistData = GetActiveOrderAndCustomerQuery['activeUserWishlists'][number];

/**
 * @description
 * A service which exposes the current active Customer & Order, which are used in many places in the
 * app. This removes the need for individual, separate calls in each component that needs the data.
 */
@Injectable({
    providedIn: 'root',
})
export class ActiveService {
    activeOrder$: Observable<CartFragment | undefined>;
    activeCustomer$: Observable<ActiveCustomerFragment | undefined>;
    activeUserWishlists$: Observable<WishlistData[] | undefined>;
    defaultShippingLocation$: Observable<CustomerShippingLocation>;

    constructor(private dataService: DataService, private stateService: StateService) {
        const activeOrderAndCustomer$ = merge(
            this.stateService
                .select((state) => state.activeOrderId)
                // We debounce here because we are detecting and setting the shipping country in an event subscriber,
                // which will be done after the adding of the first item to the cart. Without the debounce
                // the setting of the shipping country might not yet have taken place.
                .pipe(distinctUntilChanged(), debounceTime(1000)),
            this.stateService.select((state) => state.signedIn).pipe(distinctUntilChanged()),
        ).pipe(
            debounceTime(200),
            mergeMap(() => {
                return this.dataService
                    .query(GET_ACTIVE_ORDER_AND_CUSTOMER, {}, { fetchPolicy: 'network-only' })
                    .pipe(take(1));
            }),
            switchMap(() => {
                return this.dataService.query(
                    GET_ACTIVE_ORDER_AND_CUSTOMER,
                    {},
                    { fetchPolicy: 'cache-first' },
                );
            }),
            shareReplay(1),
        );
        this.activeOrder$ = activeOrderAndCustomer$.pipe(
            map(({ activeOrder }) => activeOrder),
            tap((activeOrder) => {
                if (activeOrder) {
                    this.stateService.setState('activeOrderId', activeOrder.id);
                }
            }),
        );
        this.activeCustomer$ = activeOrderAndCustomer$.pipe(map(({ activeCustomer }) => activeCustomer));
        this.activeCustomer$
            .pipe(
                map((activeCustomer) => !!activeCustomer),
                distinctUntilChanged(),
            )
            .subscribe((hasActiveCustomer) => {
                this.stateService.setState('signedIn', hasActiveCustomer);
            });
        this.activeUserWishlists$ = activeOrderAndCustomer$.pipe(
            map(({ activeUserWishlists }) => activeUserWishlists),
        );
        this.defaultShippingLocation$ = activeOrderAndCustomer$.pipe(
            map(({ defaultShippingLocation }) => defaultShippingLocation),
        );
    }

    refreshDefaultShippingLocation() {
        this.dataService.query(GetDefaultShippingLocationDocument).subscribe();
    }
}
